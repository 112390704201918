import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { mediaBreakpointUpLg, mediaBreakpointUpXl } from 'theme/breakpoints'
import { breakpointsMinHeight } from 'theme/utils'

type SectionLayoutProps = {
	className?: string
	noPaddingTop?: boolean
	small?: boolean
	noPaddingBottom?: boolean
	children: PropTypes.ReactNodeLike
	lazy?: boolean
	lazyHeights?: Parameters<typeof breakpointsMinHeight>[0]
}

type SectionProps = Omit<SectionLayoutProps, 'children' | 'lazy'>

const SectionLayoutWrapper = styled.section<SectionProps>`
	padding-top: ${props => (props.noPaddingTop ? '0' : props.small ? '16px' : '30px')};
	padding-bottom: ${props => (props.noPaddingBottom ? '0' : props.small ? '16px' : '32px')};
	background: ${props => props.theme.background};

	${mediaBreakpointUpLg} {
		padding-top: ${props => (props.noPaddingTop ? '0' : props.small ? '48px' : '58px')};
		padding-bottom: ${props => (props.noPaddingBottom ? '0' : props.small ? '48px' : '56px')};
	}

	.highlighted {
		margin-bottom: 16px;

		${mediaBreakpointUpLg} {
			margin-bottom: 28px;
		}

		${mediaBreakpointUpXl} {
			margin-bottom: 30px;
		}
	}

	${props => breakpointsMinHeight(props.lazyHeights)}
`

export const SectionLayout = ({ children, lazy, ...props }: SectionLayoutProps) => {
	const [ref, inView] = useInView({ rootMargin: '100px 0px', threshold: 0.01, triggerOnce: true, skip: !lazy })

	return (
		<SectionLayoutWrapper ref={ref} {...props}>
			{(lazy && inView) || !lazy ? children : null}
		</SectionLayoutWrapper>
	)
}
