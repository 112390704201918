import React, { useEffect, useRef, useState } from 'react'
import { LazyImageFull, ImageState } from 'react-lazy-images'
import styled from 'styled-components'
import { BlurhashCanvas } from 'react-blurhash'
import { paddingBottomByAspectRatio, positionAbsoluteAndStretch, rgbaWithHex, transition } from 'theme/utils'
import objectFitImages from 'object-fit-images'
import 'lazysizes'

if (process.browser) {
	require('picturefill')
}

type LazyImageProps = {
	src: string
	srcRetina: string
	srcPlaceholder: string
	alt: string
	aspectRatio?: string
	loadEagerly?: boolean
}

const ImageWrapper = styled.div<{ aspectRatio?: string; loaded: boolean; delay?: number }>`
	position: relative;
	width: 100%;
	${props => props.aspectRatio && paddingBottomByAspectRatio(props.aspectRatio)}

	&::before {
		${props => props.aspectRatio && positionAbsoluteAndStretch}
		content: ' ';
		${props => rgbaWithHex(props.theme.color.primary, 0.15, 'background')}
		opacity: ${props => (props.loaded ? 0 : 1)};
		transition: ${props => transition('opacity', props.delay || 0)};
	}
`

const ImageBlur = styled.div<{ visible: boolean; delay?: number; aspectRatio?: string }>`
	${props => props.aspectRatio && positionAbsoluteAndStretch}
	opacity: ${props => (props.visible ? 1 : 0)};
	transition: ${props => transition('opacity', props.delay || 0)};

	canvas {
		${props => props.aspectRatio && positionAbsoluteAndStretch}
		width: 100%;
		height: 100%;
	}
`

const Image = styled.img<{ visible: boolean; aspectRatio?: string; delay?: number }>`
	${props => props.aspectRatio && positionAbsoluteAndStretch}
	width: 100%;
	height: 100%;
	font-family: 'object-fit: cover; object-position: center;';
	opacity: ${props => (props.visible ? 1 : 0)};
	transition: ${props => transition('opacity', props.delay || 0)};
	object-fit: cover;
	object-position: center;
`

export const LazyImage = ({
	src,
	srcRetina,
	srcPlaceholder,
	alt,
	aspectRatio,
	loadEagerly = false,
}: LazyImageProps) => {
	const loadingRef = useRef<HTMLImageElement>(null)
	const actualRef = useRef<HTMLImageElement>(null)
	const [isPlaceholderLoaded, setIsPlaceholderLoaded] = useState(false)
	const [isActualLoaded, setIsActualLoaded] = useState(false)

	useEffect(() => {
		if (loadingRef.current) {
			setIsPlaceholderLoaded(loadingRef.current.complete)
		}
		if (actualRef.current) {
			setIsActualLoaded(actualRef.current.complete)
		}
	}, [loadingRef, actualRef])

	useEffect(() => {
		if (isPlaceholderLoaded) {
			objectFitImages(loadingRef.current)
		}
		if (isActualLoaded) {
			objectFitImages(actualRef.current)
		}
	}, [isPlaceholderLoaded, isActualLoaded])

	return (
		<LazyImageFull
			src={src}
			srcSet={`${srcRetina} 2x, ${src} 1x`}
			alt={alt}
			observerProps={{ rootMargin: '100px 0px', threshold: 0.01 }}
			loadEagerly={loadEagerly}>
			{({ imageProps, imageState, ref }) => {
				const isAsked = imageState !== ImageState.NotAsked
				const isLoaded = imageState === ImageState.LoadSuccess
				setIsActualLoaded(isLoaded)
				return (
					<ImageWrapper ref={ref} loaded={isPlaceholderLoaded} delay={200} aspectRatio={aspectRatio}>
						{srcPlaceholder.length ? (
							<ImageBlur visible={!isActualLoaded} delay={200} aspectRatio={aspectRatio}>
								<BlurhashCanvas hash={srcPlaceholder} width={32} height={32} />
							</ImageBlur>
						) : null}
						{isAsked ? (
							<Image
								ref={actualRef}
								className="image-normal"
								{...imageProps}
								alt={alt}
								loading={loadEagerly ? 'eager' : 'lazy'}
								visible={isLoaded}
								aspectRatio={aspectRatio}
							/>
						) : null}
					</ImageWrapper>
				)
			}}
		</LazyImageFull>
	)
}
